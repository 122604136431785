import React from "react"
import Layout from "../components/layout"

// shapes
import medium from '../images/home-cta/one/medium.svg'
import small from '../images/home-cta/one/small.svg'
import largeTwo from '../images/home-cta/two/large.svg'
import mediumTwo from '../images/home-cta/two/medium.svg'
import smallTwo from '../images/home-cta/two/small.svg'
import largeThree from '../images/home-cta/three/large.svg'
import mediumThree from '../images/home-cta/three/medium.svg'
import smallThree from '../images/home-cta/three/small.svg'
import mediumFour from '../images/home-cta/four/medium.svg'
import smallFour from '../images/home-cta/four/small.svg'
import mediumFive from '../images/home-cta/five/medium.svg'
import smallFive from '../images/home-cta/five/small.svg'

// images
import betterEvents from '../images/home-cta/one/better-evets-less-staff-time.jpg'
import bottom from '../images/home-cta/two/bottom.png'
import top from '../images/home-cta/two/top.png'
import bottomThree from '../images/home-cta/three/bottom.png'
import topThree from '../images/home-cta/three/top.png'
import imageFour from '../images/home-cta/four/image.png'
import imageFive from '../images/home-cta/five/image.png'


import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <div className="home--content site--wrapper">
      <section className="cta--wrapper one clearfix">
        <div className="cta--wrapper-left"
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-duration="500"
          data-sal-easing="ease-out-back">
          <img
            src={betterEvents}
            alt="Screenshot"
            className="img"
          />
          <img
            src={medium}
            alt="shape"
            className="svg medium"
          />
          <img
            src={small}
            alt="shape"
            className="svg small"
          />
        </div>
        <div className="cta--wrapper-right"
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-duration="500"
          data-sal-easing="ease-out-back">
          <h3>Better events, less staff time</h3>
          <p>
            Attendees can scan themselves into events via a kiosk or handheld
            scanner.
          </p>
        </div>
      </section>

      <section className="cta--wrapper two clearfix">
        <div className="cta--wrapper-left"
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-easing="ease-out-back">
          <img
            src={bottom}
            alt="Screenshot"
            className="img bottom"
          />
          <img
            src={top}
            alt="Screenshot"
            className="img top"
          />
          <img
            src={largeTwo}
            alt="shape"
            className="svg large"
          />
          <img
            src={mediumTwo}
            alt="shape"
            className="svg medium"
          />
          <img
            src={smallTwo}
            alt="shape"
            className="svg small"
          />
        </div>
        <div className="cta--wrapper-right"
          data-sal="slide-left"
          data-sal-delay="100"
          data-sal-easing="ease-out-back">
          <h3>More of the good stuff</h3>
          <p>
            Your customers get recommendations based on their personal
            preferences and evaluations.
          </p>
        </div>
      </section>

      <section className="cta--wrapper three clearfix">
        <div className="cta--wrapper-left"
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-easing="ease-out-back">
          <img
            src={bottomThree}
            alt="Screenshot"
            className="img bottom"
          />
          <img
            src={topThree}
            alt="Screenshot"
            className="img top"
          />
          <img
            src={largeThree}
            alt="shape"
            className="svg large"
          />
          <img
            src={mediumThree}
            alt="shape"
            className="svg medium"
          />
          <img
            src={smallThree}
            alt="shape"
            className="svg small"
          />
        </div>
        <div className="cta--wrapper-right"
          data-sal="slide-left"
          data-sal-delay="200"
          data-sal-easing="ease-out-back">
          <h3>Browse events from anywhere</h3>
          <p>
            Flexible event browsing experience for all devices, so new and
            similar events are easy to find.
          </p>
        </div>
      </section>

      <section className="cta--wrapper four clearfix">
        <div className="cta--wrapper-left"
          data-sal="slide-right"
          data-sal-delay="200"
          data-sal-easing="ease-out-back">
          <img
            src={imageFour}
            alt="Screenshot"
            className="img"
          />
          <img
            src={mediumFour}
            alt="shape"
            className="svg medium"
          />
          <img
            src={smallFour}
            alt="shape"
            className="svg small"
          />
        </div>
        <div className="cta--wrapper-right"
          data-sal="slide-left"
          data-sal-delay="100"
          data-sal-easing="ease-out-back">
          <h3>Personalize to your library</h3>
          <p>
            Add your logo and customize colors to match your unique branding.
          </p>
        </div>
      </section>

      <section className="cta--wrapper five clearfix">
        <div className="cta--wrapper-left"
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-easing="ease-out-back">
          <img
            src={imageFive}
            alt="Screenshot"
            className="img"
          />
          <img
            src={mediumFive}
            alt="shape"
            className="svg medium"
          />
          <img
            src={smallFive}
            alt="shape"
            className="svg small"
          />
        </div>
        <div className="cta--wrapper-right"
          data-sal="slide-left"
          data-sal-delay="200"
          data-sal-easing="ease-out-back">
          <h3>Quickly create more events</h3>
          <p>
            Built-in templates make creating your most popular, recurring events
            fast and simple.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
